import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, Container } from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'

import request from '../../../lib/request'
import TextField from '../../atoms/TextField'
import Checkbox from '../../atoms/Checkbox'
import Spinner from '../../atoms/Spinner'
import useConfig from '../../../hooks/useConfig'
import useFetch from '../../../hooks/useFetch'
import useSnackbar from '../Snackbar/useSnackbar'
import useGooglePlaces from '../../../hooks/useGooglePlaces'
import { useAuth } from '../../../hooks/useAuth'
import CoordsConfirmDialog from './CoordsConfirmDialog'
import { HeadquarterSchema } from './validators'
import { fillFormValues, createMarker } from './helpers/form-helper'

export default function HeaquarterForm({ companyId, headquarterId }) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation()
  const { openConfirmDialog } = useConfirmDialog()

  const marker = useRef(null)
  const { gmapKey } = useConfig()
  const [mapElementId, setMapElementId] = useState(null)
  const [searchAddress, setSearchAddress] = useState('')
  const { map, place, error } = useGooglePlaces({
    searchAddress,
    googleMapsKey: gmapKey,
    mapElementId,
  })

  const { isAdmin } = useAuth()

  const isNew = !headquarterId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const [coordsConfirmDialogOpen, setCoordsConfirmDialogOpen] = useState(false)
  const [boundCallApiFn, setBoundCallApiFn] = useState(null)

  const fetchHeaquarter = useCallback(async () => {
    if (!headquarterId) {
      return null
    }
    return request.get(`/admin/headquarters/${headquarterId}`)
  }, [headquarterId])

  const {
    fetchedObject: headquarter,
    isFetching,
    fetchError,
  } = useFetch(fetchHeaquarter)

  useEffect(() => {
    if (!headquarterId || headquarter) {
      setMapElementId('map')
    }
  }, [headquarterId, headquarter])

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  useEffect(() => {
    if (!headquarter || !map) {
      return
    }

    if (headquarter.lat && headquarter.lng) {
      const location = new window.google.maps.LatLng(
        headquarter.lat,
        headquarter.lng
      )
      map.setCenter(location)
      createMarker(location, marker, map)
    }
  }, [headquarter, map])

  useEffect(() => {
    if (error) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`googleApi.errors.${error}`),
      })
      return
    }

    if (!place?.geometry?.location) {
      return
    }

    createMarker(place.geometry.location, marker, map)
    map.setCenter(place.geometry.location)
    fillFormValues(formikRef.current, place)
  }, [place, error, map, showSnackbarMessage, t])

  const searchAddressClickHandler = (e) => {
    e.preventDefault()
    const searchedAddress = formikRef.current.values.searchedAddress
    if (searchedAddress) {
      setSearchAddress(searchedAddress)
    }
  }

  async function handleSubmit(values, actions) {
    if (
      headquarter &&
      (headquarter.lat !== values.lat || headquarter.lng !== values.lng)
    ) {
      setBoundCallApiFn(() => async () => {
        await callApi(values, actions)
        setCoordsConfirmDialogOpen(false)
      })
      setCoordsConfirmDialogOpen(true)
    } else {
      await callApi(values, actions)
    }
  }

  async function callApi(values, actions) {
    try {
      const data = {
        name: values.name,
        companyId,
        isMain: values.isMain,
        searchedAddress: values.searchedAddress,
        placeId: values.placeId,
        completeAddress: values.completeAddress,
        address: values.address,
        zipCode: values.zipCode,
        city: values.city,
        country: values.country,
        lat: values.lat ? Number(values.lat) : null,
        lng: values.lng ? Number(values.lng) : null,
        checkMaxMeters: values.checkMaxMeters
          ? Number(values.checkMaxMeters)
          : null,
        maxDriverExtraTravel: values.maxDriverExtraTravel
          ? Number(values.maxDriverExtraTravel)
          : null,
      }

      if (isNew) {
        const { id } = await request.post(`/admin/headquarters`, data)
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/headquarter/${id}`)
      } else {
        await request.patch(`/admin/headquarters/${headquarterId}`, data)
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(headquarter, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.message,
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('headquarterDetailPage.deleteConfirmDescription', {
        name: headquarter?.name,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/headquarters/${headquarterId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/headquarters`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0}>
      <Formik
        innerRef={formikRef}
        validationSchema={HeadquarterSchema(t)}
        initialValues={{
          name: headquarter?.name || '',
          isMain: headquarter?.isMain || false,
          searchedAddress: headquarter?.searchedAddress || '',
          placeId: headquarter?.placeId || '',
          completeAddress: headquarter?.completeAddress || '',
          address: headquarter?.address || '',
          zipCode: headquarter?.zipCode || '',
          city: headquarter?.city || '',
          country: headquarter?.country || '',
          lat: headquarter?.lat || '',
          lng: headquarter?.lng || '',
          checkMaxMeters: headquarter?.checkMaxMeters || '',
          maxDriverExtraTravel: headquarter?.maxDriverExtraTravel || '',
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <TextField
              fullWidth
              margin="dense"
              required
              inputProps={{
                autoComplete: 'off',
                readOnly,
              }}
              label={t('entities.headquarter.name')}
              name="name"
              size="small"
            />
            <Checkbox
              inputProps={{
                disabled: readOnly,
              }}
              color="primary"
              label={t('entities.headquarter.isMain')}
              name="isMain"
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <TextField
                  fullWidth
                  margin="dense"
                  inputProps={{
                    autoComplete: 'off',
                    readOnly,
                  }}
                  label={t('entities.headquarter.searchedAddress')}
                  name="searchedAddress"
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      searchAddressClickHandler(e)
                    }
                  }}
                />
              </Box>
              <Box ml={3} mt={0.5}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchAddressClickHandler}
                  disabled={pageMode === 'view'}
                >
                  Cerca
                </Button>
              </Box>
            </Box>
            <Box
              mb={2}
              id={mapElementId}
              style={{ height: '300px', marginTop: '20px' }}
            />
            <TextField
              fullWidth
              margin="dense"
              inputProps={{
                autoComplete: 'off',
                readOnly: true,
              }}
              label={t('entities.headquarter.completeAddress')}
              name="completeAddress"
              disabled
              size="small"
            />
            <TextField
              fullWidth
              margin="dense"
              inputProps={{
                autoComplete: 'off',
                readOnly: true,
              }}
              label={t('entities.headquarter.address')}
              name="address"
              disabled
              size="small"
            />
            <Box>
              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
                label={t('entities.headquarter.zipCode')}
                name="zipCode"
                disabled
                size="small"
              />
              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
                label={t('entities.headquarter.city')}
                name="city"
                disabled
                size="small"
              />
              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
                label={t('entities.headquarter.country')}
                name="country"
                disabled
                size="small"
              />
            </Box>
            <Box>
              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
                label={t('entities.headquarter.lat')}
                name="lat"
                disabled
                size="small"
              />
              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
                label={t('entities.headquarter.lng')}
                name="lng"
                disabled
                size="small"
              />
              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
                label={t('entities.headquarter.placeId')}
                name="placeId"
                disabled
                size="small"
              />
            </Box>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{
                autoComplete: 'off',
                readOnly,
              }}
              label={t('entities.headquarter.checkMaxMeters')}
              name="checkMaxMeters"
              size="small"
            />

            <TextField
              fullWidth
              type="number"
              margin="dense"
              inputProps={{
                autoComplete: 'off',
                readOnly,
              }}
              size="small"
              label={t('entities.company.maxDriverExtraTravelLabel')}
              name="maxDriverExtraTravel"
            />

            <Container>
              {readOnly ? (
                <>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                      <Button
                        onClick={handleDelete}
                        color="primary"
                        aria-label={t('common.delete')}
                      >
                        {t('common.delete')}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isNew ? (
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                  ) : (
                    <Button
                      type="reset"
                      color="secondary"
                      aria-label={t('common.cancel')}
                    >
                      {t('common.cancel')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {isNew ? t('common.add') : t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
      <CoordsConfirmDialog
        isOpen={coordsConfirmDialogOpen}
        handleConfirm={boundCallApiFn}
        handleClose={() => setCoordsConfirmDialogOpen(false)}
      />
    </Box>
  )
}

HeaquarterForm.propTypes = {
  companyId: T.string.isRequired,
  headquarterId: T.string,
}
