import T from 'prop-types'
import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'

const fetchTeamMembers = async (query, filters, challengeTeamId) => {
  const search = filters.search || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''
  const { items: data, total: totalCount } = await request.get(
    `/admin/challenge-team-members?challengeTeamId=${challengeTeamId}&search=${search}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )
  const offset = query.pageSize * query.page
  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function TeamMembersTable({ challengeId, teamId }) {
  const [filters] = useState({ search: '' })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  // Adjust columns for team members
  const columns = [
    {
      title: t('teamMembersPage.tableHeaders.lastname'),
      field: 'lastName',
      render: (params) => params.lastName,
    },
    {
      title: t('teamMembersPage.tableHeaders.firstname'),
      field: 'firstName',
      render: (params) => params.firstName,
    },
    // Add more columns as needed for team members
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      try {
        return await fetchTeamMembers(query, filters, teamId)
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      }
    },
    [filters, teamId, showSnackbarMessage, t]
  )

  return (
    <Card className={classes.root}>
      {/* Use a suitable FilterBar for team members if needed */}
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        options={{ paging: false }}
      />
    </Card>
  )
}

TeamMembersTable.propTypes = {
  teamId: T.string.isRequired,
  challengeId: T.string.isRequired,
}
